import './index.scss';
import $ from 'jquery';
import cookies from 'jquery.cookie';
$(document).ready(function(){
  let lang = ($("html").attr("lang") || 'xx').substring(0, 2);
  const BOOKMARK_KEY = "offer_bookmarks_" + lang;
  let bookmarks = $('.offer-bookmarks-count[data-ids]').data('ids') || '';
  bookmarks =  bookmarks && bookmarks.split  ? bookmarks.split(",").filter(x=>x) : [];

  let bookmark = (jobId)=>{
    jobId = ''+jobId
    if (!bookmarks.includes(jobId)){
      bookmarks.push(jobId)
    }
    else{
      bookmarks = bookmarks.filter(x=>x!=jobId)
    }
    bookmarks = bookmarks.filter(x=>x);
    $.cookie(BOOKMARK_KEY, bookmarks.join(","), {
      expires: 30,
      path: "/",
    });
    refresh()
  }

  let refresh = ()=>{
    $('[data-offer-bookmark]').removeClass('--bookmarked')
    let count = bookmarks.length;
    bookmarks.map(x=>{
      $('[data-offer-bookmark="'+x+'"]').addClass('--bookmarked')
    })
    $('.offer-bookmarks-count').html('('+count+')')
  }

  $(document).on('click', '[data-offer-bookmark]', function(e){
    e.preventDefault();
    let jobId = $(this).attr('data-offer-bookmark')
    bookmark(jobId)
  })
  refresh();
})
